.wsfmc-properties-admin__property {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;

  .wsfmc-properties-admin__property-label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    letter-spacing: 1px;
    line-height: 1.2;
    text-align: left;
    margin: 10px;
    padding: 0;
  }

  .wsfmc-properties-admin__property-description {
    font-size: 12px;
    font-weight: 400;
    color: #333;
    letter-spacing: 1px;
    line-height: 1.2;
    text-align: left;
    margin-right: 10px;
    padding: 0;
  }
}
