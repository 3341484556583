.builderSuite-autocomplete {
  .MuiOutlinedInput-root {
    height: 130px;
    display: block;
    width: 100%;
  }
  .MuiAutocomplete-inputRoot {
    align-items: start;
  }
  .MuiAutocomplete-inputRoot {
    flex-wrap: wrap;
  }
  .MuiAutocomplete-tag {
    float: left;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
    .MuiAutocomplete-input {
      width: auto;
      flex-grow: 1;
    }
  }
  .autocomplete-input {
    input.MuiInputBase-input {
      width: 100%;
    }
  }
}
