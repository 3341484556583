@import '../../styles/variables.scss';

.rich-text__editor {
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(7, 20, 35, 0.2);
  margin: 1rem 0;
  &-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;

    button {
      height: 28px;
      width: 28px;
      display: grid;
      place-items: center;
      border-radius: 3px;
      border: none;
      background-color: #ffffff;
      outline: none;
      color: #020929;
      cursor: pointer;

      &:hover {
        background-color: #e0e0e086;
      }
    }

    select {
      padding: 7px;
      border: 1px solid #020929;
      border-radius: 3px;
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      gap: 3px;
    }
    input[type='color'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      width: 24px;
      height: 28px;
      border: none;
      cursor: pointer;
    }
    input[type='color']::-webkit-color-swatch {
      border-radius: 15px;
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #020929;
    }
    input[type='color']::-moz-color-swatch {
      border-radius: 15px;
      box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #020929;
    }
    #text-input {
      margin-top: 10px;
      border: 1px solid #dddddd;
      padding: 20px;
      height: 50vh;
    }
    .active {
      background-color: #e0e9ff;
    }
  }
  &-content {
    margin-top: 1rem;
    min-height: 125px;
    border: 1px solid #dbdbdb;
    padding: 1rem;

    &:focus-visible {
      border: 1px solid #dfdfdf;
      outline: none;
    }

    img {
      width: 100%;
    }
  }
}
.dark {
  .MuiPaper-root {
    background: $darkmode-background-primary;

    span,
    p,
    input,
    fieldset,
    label,
    .MuiSelect-select {
      color: #ffffff;
    }

    .rich-text__editor {
      background: $darkmode-background-primary;

      &-options {
        button {
          background: $darkmode-background-primary;
          color: #ffffff;
        }
      }

      .active {
        background-color: #33465c;
      }

      &-content {
        color: #fff;
        border: 1px solid #343e57;
      }
    }
  }
}
