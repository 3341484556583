.de-dependency-graph-legend {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 15px;
  background-color: rgba(255, 255, 255);
  border-radius: 10px;
  width: 444px;
  // height: 280px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0;
  transition: width 0.3s, height 0.3s ease-in-out;
  overflow-y: hidden;

  .de-dependency-graph-legend__header {
    border-radius: 10px;
    margin-left: -10px;
    padding: 0 0 0 10px;
  }

  .de-dependency-graph-legend__header:hover {
    cursor: pointer;
    background-color: rgb(240, 240, 240);
  }

  &[data-is-collapsed='true'] {
    width: 150px;
    // height: 65px;
    box-shadow: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__text {
    // font-family: monospace;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }

  &__rectangle {
    height: 35px;
    width: 50px;
    border: solid 2px black;
    margin-right: 10px;
  }

  &__arrow {
    margin-right: 10px;
    min-width: 50px;
  }

  &__cylinder {
    position: relative;
    margin-right: 10px;

    div:nth-child(1) {
      border-left: solid 2px black;
      border-right: solid 2px black;
      height: 30px;
      width: 50px;
    }

    div:nth-child(2) {
      border: solid 2px black;
      width: 50px;
      position: absolute;
      top: -5px;
      border-radius: 100%;
      height: 10px;
    }

    div:nth-child(3) {
      border: solid 2px black;
      width: 50px;
      position: absolute;
      bottom: -4px;
      border-radius: 100%;
      height: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}
