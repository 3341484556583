.card {
  border-radius: 0.8rem;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 0.8rem;
  // flex-grow: 1;
  flex: 1 1 0;
  width: 0;

  &-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
