@import '../../styles/variables.scss';

.introjs-tooltip {
  min-width: 375px;
  padding: 0.5rem 1rem;
}

.introjs-progressbar {
  background: $color-primary;
}

.introjs-fixedTooltip,
.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 15%) 0px 0px 1px 2px,
    rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.introjs-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  font-size: 0.875rem;
  letter-spacing: 0.02857em;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  text-shadow: none;
  font-weight: bold;
  text-transform: none;
  line-height: 1;
  min-width: max-content;
  min-height: 32px;

  &:hover,
  &:active {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border: none;
    color: #fff;
  }

  &:focus {
    outline: 0;
    border: 0;
    margin: 0;
    box-shadow: none;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    text-shadow: none;

    &:hover {
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    }
  }
}

.introjs-nextbutton {
  background-color: $color-primary !important;
}

.introjs-prevbutton,
.introjs-disabled {
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid #0060af80 !important;
  color: $color-primary !important;
  background-color: #fff !important;
  box-shadow: none !important;

  &:hover {
    text-decoration: none;
    background-color: rgba(25, 118, 210, 0.04) !important;
    border: 1px solid $color-primary;
    color: $color-primary;
  }
}

.introjs-disabled {
  opacity: 0.5;
  cursor: default !important;

  &:hover {
    border: 1px solid #0060af80 !important;
    color: $color-primary !important;
    background-color: #fff !important;
  }
}
