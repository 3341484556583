@import '../../styles/variables.scss';

.css-1mxz8qt-MuiPaper-root,
.css-1pty0ct {
  border: none !important;
  box-shadow: none !important;
}

.css-1q7lp8d,
.css-1gplja-MuiTable-root {
  min-width: unset !important;
}

.css-2pbtu6-MuiTypography-root,
.css-kr7kgz {
  text-anchor: start !important;
  dominant-baseline: auto !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgb(34 34 34 / 90%);
}
.css-1mxz8qt-MuiPaper-root,
.css-1m13d3u-MuiTableCell-root,
.css-dv0mq-MuiTableCell-root,
.css-jtlhu6-MuiTablePagination-root,
.css-dsuxgy-MuiTableCell-root,
.css-1pty0ct,
.css-7p4hjk,
.css-1thfh4x,
.css-1o6fzn1,
.css-o9ey65 {
  background-color: unset !important;
  color: unset !important;
  // font-weight: 600 !important;
}

.css-6qfsqn-MuiTableCell-root,
.css-1y3yv5w-MuiTableCell-root,
.css-1howxi1-MuiTableCell-root,
.css-12pjbv5,
.css-1dp87nm,
.css-13njpue {
  font-weight: 600 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: $color-primary !important;
}

thead {
  background: $color-primary;

  tr span {
    color: #fff !important;
    font-weight: 600 !important;
  }

  tr svg {
    color: rgba(255, 255, 255, 0.699) !important;
  }

  tr th {
    background-color: unset !important;
  }
}

span.bu-name-tooltip {
  padding: 4px 2px;
  margin: 0px 2px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  cursor: help;
  // text-align: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.data-table {
  .jss12 {
    padding: 1rem;

    & > div > div {
      border-radius: 12px !important;
    }
  }
}
