.notification-dialog {
  .MuiDialog-container.MuiDialog-scrollPaper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .MuiPaper-root.MuiPaper-elevation {
      max-height: 70vh;
    }
  }
}
