.mc-login {
  &__response {
    display: flex;
    justify-content: center;
    margin: 1rem;

    &-icon {
      width: 300px !important;
      height: 300px !important;
      border-radius: 50%;
    }

    .success {
      background: #edf7ed;
      color: green !important;
    }

    .error {
      background: #fdeded;
      color: #ff5353 !important;
    }

    .warning {
      background: #fff4e5;
      color: #f5be72 !important;
    }
  }

  .explain div.MuiAlert-standardWarning {
    background-color: transparent;
  }
}
