@import '../../styles/variables.scss';

#userListManager {
  .MuiTableCell-root {
    padding: 0.2rem 1rem;
  }

  .MuiDialog-container > .MuiPaper-root {
    max-height: 600px;
  }

  thead th {
    border: none;
  }

  th {
    font-weight: bold;
    color: #fff;
  }

  .business-unit__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
      font-size: 0.875rem;
    }

    .bu-avatar {
      background: $color-primary;
      transform: scale(0.85);
    }
    .MuiListItemAvatar-root {
      min-width: 45px;
    }
  }
}

#userListManager.dark {
  .MuiPaper-root {
    background: $darkmode-background-primary;
  }

  .data-table__title span,
  .MuiTableCell-root,
  .MuiButtonBase-root svg,
  .business-unit__details span {
    color: #fff;
  }
}
