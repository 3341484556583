.widget {
  display: flex;
  justify-content: space-between;
  height: 100px;

  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: bold;
      font-size: 14px;
      text-anchor: start;
      dominant-baseline: auto;
      font-size: 16px;
      font-weight: 700;
      color: rgb(34 34 34 / 90%);
    }

    .counter {
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: rem;
      color: rgba(34, 34, 34, 0.9);
      line-height: 1;
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
    }

    .percentage {
      display: flex;
      align-items: center;
      font-size: 14px;

      &.positive {
        color: green;
      }
      &.negative {
        color: red;
      }
    }

    .icon {
      font-size: 25px;
      padding: 5px;
      border-radius: 5px;
      align-self: flex-end;
      margin-bottom: 0.5rem;
    }
  }
}
