@import '../../styles/variables.scss';

.spinner {
  border: 7px solid rgba(255, 255, 255, 0);
  border-top: 7px solid $color-primary;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
