.error-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  img {
    height: 90%;
    opacity: 0.2;
  }

  p {
    font-size: 14px;
    font-weight: bold;
    opacity: 0.7;
  }
}
