@import '../../styles/variables.scss';

.advm-popup {
  position: fixed;
  width: 100%;
  // background-color: rgba($color: #ff4f4f, $alpha: 0.3);
  z-index: 1000;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  top: 50px;
  // padding-top: 10px;
  // padding-bottom: 10px;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;

  h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }
}
