.ampscript-editor__editor {
  textarea {
    height: 400px;
    width: 100%;
    font-family: monospace;
    font-size: 13px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    resize: none;
    overflow: auto;
  }
}

.ampscript-editor__preview {
  position: relative;
  height: 400px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: text;
  overflow: auto;

  pre {
    font-size: 13px;
    font-family: monospace;
  }

  &.loading {
    background-color: rgb(158, 158, 158);
    cursor: progress;
  }
}
