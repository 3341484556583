$color-primary: #0060af;
$color-primary-light: #2375b8;
$color-primary-dark: #014f8f;
$darkmode-color-primary: #58b4ff;
$darkmode-color-primary-dark: #3c96e0;
$darkmode-background-primary: #243447;
$darkmode-background-secondary: #11192094;
$color-text-dark: #000000;
$color-text-light: #ffffff;
$color-success: #4bb543;
$color-danger: #ff0e0e;

:export {
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorPrimaryDark: $color-primary-dark;
  darkmodeColorPrimary: $darkmode-color-primary;
  darkmodeColorPrimaryDark: $darkmode-color-primary-dark;
  darkmodeBackgroundPrimary: $darkmode-background-primary;
  darkmodeBackgroundSecondary: $darkmode-background-secondary;
  colorTextDark: $color-text-dark;
  colorTextLight: $color-text-light;
  colorSuccess: $color-success;
  colorDanger: $color-danger;
}
