@import '../styles/variables.scss';

.audits {
  background: #f9f9f9;
  border-radius: 0.8rem;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
  padding: 2.4rem;

  // .MuiAccordionSummary-expandIconWrapper {
  //   position: absolute;
  //   left: 17px;
  // }

  a {
    color: $color-primary;
    transition: all 0.2s ease;
    font-weight: bold;
    text-decoration: none;
    line-height: 1.2;

    &:hover {
      color: $color-primary-dark !important;
    }
  }

  .de-dependency {
    overflow: hidden;
    width: 100%;
    height: 58vmin;
    padding: 2rem 0;
    position: relative;

    &__container {
      background: #f1f1f1;
      border-radius: 0.8rem;
      box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
      padding: 2rem;
    }

    .graphviz-parent {
      overflow: hidden;
      svg {
        width: auto;
        height: 100%;
        transform-origin: top left;

        g.node {
          cursor: pointer;
          display: inline-block;
          -ms-user-select: none;
          -webkit-user-select: none;
          user-select: none;

          ellipse {
            fill: lightpink;
          }

          path,
          polygon {
            fill: transparent;
          }

          path.selected,
          polygon.selected {
            fill: bisque;
          }
        }
        g.graph {
          padding: 1rem;
          polygon {
            fill: rgb(243 242 239);
          }
          text {
            font-size: 12px;
          }
        }
      }
    }

    > * {
      margin-bottom: 2rem;
    }
  }

  &-title-bottom {
    margin: 1rem 0 0 0;
    display: inline-block;
  }

  &-workflow-popup {
    a.github {
      color: darkblue;
      text-decoration: none;
      display: inline-block;

      &:hover {
        color: darkred;
      }
    }

    .MuiPaper-root {
      margin-top: 90px;
      margin-bottom: 45px;
      padding-bottom: 10px;
    }

    .required {
      padding-top: 10px;
    }
    .screenshot {
      padding-top: 10px;
    }

    .MuiList-root {
      list-style-type: disc;
    }

    .MuiListItem-root {
      /* display: list-item; */
      /* list-style: disc; */
      padding: 0 0 0 20px;
    }

    .MuiListItemText-root {
      display: list-item;
      list-style: disc;
    }
  }

  .filter-bu {
    margin-left: 16px;

    .search-field {
      width: 400px;
    }
  }

  .bu-count {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    padding: 0 16px;

    span {
      color: $color-primary;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .bu-reload {
    vertical-align: middle;
    color: darkgray;
    padding: 0 8px 0 8px;
  }

  & .audits-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px;
  }

  & .audits-title {
    display: flex;
    align-items: flex-end;
    gap: 0.2rem;
    margin-bottom: 2rem;

    &__icon {
      font-size: 2rem;
      color: $color-primary;
    }
  }

  button {
    &.refresh-list {
      padding: 0.2rem;
      transform: scale(1.2);
    }
  }

  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: whitesmoke;
    }

    &:hover {
      background-color: rgb(243, 243, 252);
      color: $color-primary-dark;
      font-weight: bold;
    }
  }

  .rdt_Table div.rdt_TableCol div {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  .rdt_Table div.rdt_TableCell div {
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

    &:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        white-space: nowrap;
        /* overflow: auto; */
        z-index: 1;
        border: thin solid lightgray;
        background-color: white;
        opacity: 1;
        position: absolute;
        font-weight: bold;
        text-overflow: unset;
        /* width: 100%; */
        padding: 10px 10px 10px 10px;
        -webkit-user-select: all;
        user-select: all;
        left: 0;
        right: 0;
        text-align: right;
      }
    }
  }

  .rdt_TableHeadRow {
    border: none;
  }

  .rdt_TableRow {
    &.bs-results-error {
      .rdt_TableCell div {
        color: rgb(255, 86, 86);
      }
    }
    &.bs-results-warn {
      .rdt_TableCell div {
        color: rgb(194, 110, 0);
      }
    }
  }
  .bs-results-collecting * {
    color: rgb(37, 0, 204);
  }

  .expanded-row {
    // font-size: smaller;

    tr {
      &.filler {
        height: 5px;
      }
      &.bs-results-error {
        color: rgb(255, 86, 86);
      }
    }
    td {
      vertical-align: top;

      &.identifier-column {
        min-width: 100px;
      }

      &.label {
        text-align: right;
      }

      &.value {
        font-weight: bold;
        padding-left: 10px;
      }

      table.package-install-result {
        font-weight: normal;
        thead {
          background-color: transparent !important;
          th {
            padding: 5px 5px 5px 10px;
          }
        }
        tbody {
          td {
            padding: 1px 5px 1px 10px;
          }
        }
      }
    }
  }

  .bu-list-item {
    .bu-avatar {
      background: $color-primary;
    }
    .bu-parent {
      font-size: smaller;
      text-align: right;
      color: grey;
    }

    .bu-mid {
      font-size: smaller;
      color: #222;

      &-text {
        color: #222;

        button {
          pointer-events: auto;
        }
      }
    }

    &__panel {
      width: 100%;
      background: rgb(238 236 236);
      border-radius: 0.8rem !important;
      box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
      transition: all 0.1s ease;
    }

    .bu-repository {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: smaller;
      color: #222;

      a {
        color: $color-primary;
        pointer-events: auto;
      }

      .search-unmatched {
        color: $color-primary !important;
      }

      svg {
        font-size: 20px;
      }

      a:hover {
        color: #f20303;
      }
    }

    .bu-lastSyncDate {
      font-size: smaller;

      &-text {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        color: #222;
      }

      .value {
        color: #222;
      }

      &.overdue {
        .overdue-warning {
          padding: 0;
          margin: -5px;
          transform: scale(0.8);
          color: #f20303 !important;
          transform-origin: left;
          svg {
            vertical-align: middle;
          }
        }
      }

      button {
        color: $color-primary;
        padding: 0;
        transform: scale(0.8);
        transform-origin: left;
        pointer-events: auto;

        &:hover {
          color: $color-primary-dark;
        }
      }
    }

    .MuiGrid-root {
      -ms-user-select: text !important;
      -webkit-user-select: text !important;
      user-select: text !important;
    }

    .search-match {
      color: indianred !important;
      font-weight: bold;
    }

    .search-unmatched {
      color: #222;
    }

    a {
      font-weight: bold;
      color: #222;
      text-decoration: none;
      line-height: 1.2;
    }

    .bu-name {
      font-weight: bold;
      color: #222;
    }

    .bu-link {
      a {
        display: flex;
        color: $color-primary;

        &:hover {
          color: darkred;
          cursor: pointer;
        }
      }
    }

    .audit-item,
    .audit-item-text {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .audit-item {
      &-text {
        a {
          display: flex;
          align-items: center;
          gap: 0.3rem;
        }
      }

      &-icon {
        color: #222;
        font-size: 20px;
      }

      &-title {
        font-size: 15px;
      }
    }

    &:nth-child(odd) .MuiAccordion-root {
      // background-color: whitesmoke;
    }

    .MuiAccordion-root {
      border: solid thin lightgray;

      .Mui-disabled {
        opacity: 1;
      }

      &.Mui-disabled:hover {
        background-color: rgba(0, 0, 0, 0.12);
        border: solid thin lightgray;
      }

      &:hover {
        background-color: rgb(243, 243, 252);
        border-color: #222;
      }

      .bu-accordion-details {
        display: flex;
        justify-content: space-between;
        margin: -10px 55px 0 55px;

        ul {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
        }

        a {
          color: $color-primary;

          &:hover {
            color: darkred;
          }
        }
      }

      .bu-accordion-summary {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

.bu-details-seperator {
  margin: 20px 0 20px 0;
  border-top: thin solid #eee;
  color: #eee;
}

.data-table-container {
  width: 100%;
  background: rgb(241 241 241);
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 2rem;
  position: relative;

  .data-table {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
    border-radius: 0.8rem;

    header {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2rem;
      background: transparent;
    }

    & > *,
    nav {
      background: rgb(241 241 241);
    }
  }

  .rdt_Table {
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }

  .rdt_TableHeadRow > * {
    color: #f3f3f3;
    text-align: left;

    div {
      &:hover {
        color: #ffffff;
        opacity: 1;
      }
    }
  }

  .rdt_TableHeadRow > * {
    padding: 15px 15px;
    font-size: 14px;
    font-weight: bold;
  }

  .rdt_TableRow {
    border: none !important;
  }

  .rdt_TableRow > * {
    padding: 9px 15px;
    font-size: 14px;
  }

  .rdt_TableRow button {
    border-radius: 50%;
    width: 28px;
    height: auto;
  }
}

button {
  &.copy-bu-field {
    pointer-events: auto;
    height: 8px;
    padding: 0;

    &:hover {
      color: rgb(12, 12, 114);
    }

    svg {
      width: 0.7em;
      height: 0.7em;
    }

    span {
      padding: 0;
    }
  }
}

.bu-flags {
  font-size: smaller;
  white-space: nowrap;
  color: #222;
  span {
    display: inline-block;
    vertical-align: middle;
  }

  &-box {
    margin-top: 10px;
  }

  .MuiSvgIcon-root {
    vertical-align: middle;
    color: $color-primary;
  }

  .MuiSvgIcon-root.abnormal {
    vertical-align: middle;
    color: darkred;
  }

  .MuiSvgIcon-root.disabled {
    vertical-align: middle;
    color: rgb(197, 196, 170);
  }
}

.flash-popper {
  .popper-box.copied {
    padding: 5px;
    margin-left: 5px;
    border-radius: 10px;
    background-color: white;
    border: solid thin #ccc;
  }
}

.backButtonBox {
  // position: absolute;
  // top: 10px;
  // left: 10px;
  position: fixed;
  top: 57px;
  left: 7px;
  z-index: 11;
  transition: all 0.2s ease;

  &.invalid {
    display: none;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    background: $color-primary;
    border-radius: 50px;
    padding: 0px 5px 0px 0px;
    transform: scale(0.48);
    transform-origin: left center;
    transition: all 0.1s ease;

    &:hover {
      transform: scale(0.49);
    }
  }

  svg {
    font-size: 48px;
    transition: all 0.2s ease;
    color: rgb(243 242 239);
  }

  span {
    color: rgb(243 242 239);
    font-weight: 600;
  }
}

.flash-popper {
  position: absolute;
  z-index: 10000;
}

.search-close-icon {
  color: $color-primary;
  transform: scale(0.8);
  transform-origin: right;
  cursor: pointer;
}

.clear-button {
  border-radius: 50%;
  transform: scale(0.8) translateX(-7px);
  color: $color-primary;
}

.expand-icon {
  cursor: pointer;
  pointer-events: auto;
  font-size: 36px;
  transition: all 0.8s ease;

  &:hover {
    transform: scale(1.04);
    color: rgb(73, 73, 218);
  }
}

.builder-suite-dialog-title {
  .bu-avatar {
    background: $color-primary;
  }
}

.audits.builder-suite.builder-suite-package {
  div.package-ui {
    width: 100%;
    background: #f1f1f1;
    border-radius: 0.8rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 2rem;
  }

  .object-selection {
    .MuiAutocomplete-root {
      .MuiChip-label {
        padding-bottom: 1px; // without this, underscores disapear.
      }
    }
  }
}

.builder-suite-package.selected-objects-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 100vh;
  .MuiDialog-paper {
    min-height: 50vh;
    min-width: 50vw;
    max-width: 100vw;
    background: rgb(243 242 239);
    margin-top: 90px;
    margin-bottom: 45px;
  }

  li {
    list-style-type: none;
    margin-left: 15px;
  }
}
