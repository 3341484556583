.wsfmc-notifications__popover {
  width: 500px;
  max-height: 600px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: absolute;
  top: 10px;
  left: -470px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.wsfmc-notifications__popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e0e0;
  padding: 8px;
}

.wsfmc-notifications__popover-header__refresh-btn {
  cursor: pointer;
}

.wsfmc-notifications__popover-content {
  max-height: 500px;
  min-height: 100px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  align-items: center;
}

.wsfmc-notifications__popover-content-loading {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wsfmc-notifications__popover-content-empty {
  margin-top: 20px;
}

.wsfmc-notifications__popover-content::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.wsfmc-notifications__popover-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.wsfmc-notifications__popover-content::-webkit-scrollbar-thumb {
  background: #999;
}

/* Handle on hover */
.wsfmc-notifications__popover-content::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.wsfmc-notifications .wsfmc-notifications__polling-error {
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
}

.wsfmc-notifications .wsfmc-notifications__polling-error a.login,
.wsfmc-notifications .wsfmc-notifications__polling-error a.login:visited {
  text-decoration: none;
  color: #0060af;
}