@import '../../styles/variables.scss';

.navbar {
  height: 50.3px;
  background: #fff;
  border-bottom: 0.5px solid rgb(235, 230, 230);
  position: fixed;
  width: 100%;
  z-index: 4000;

  .sidebar-center__icon.dds:hover {
    // background-image: '/android-chrome-192x192.png';
  }

  &-logo {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    padding: 0rem;
    transform: translateX(-20px);

    .logo {
      display: flex;
      justify-content: flex-start;

      cursor: pointer;
      transition: all 0.2s ease;
      min-width: 220px;

      img {
        width: 80%;
        height: 80%;
        max-width: 220px;
        min-width: 100px;
      }
    }
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;

  .navbar-center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    .logo {
      color: $color-primary;
    }
    ul {
      list-style: none;
      display: flex;
      gap: 0.5rem;
    }

    .title {
      font-size: 11px;
      font-weight: bold;
      color: #999;
      text-transform: uppercase;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    &__icon {
      color: rgb(87 87 241);
      margin-right: 3px;
    }

    li a {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-decoration: none;

      svg {
        transition: all 0.1s ease;
        font-size: 1.8rem !important;
      }

      &:hover svg {
        transform: scale(1.05);
      }
    }

    span {
      font-size: 15px;
      font-weight: 600;
      color: #888;
      transition: all 0.2s ease;
    }
  }

  .navbar-right {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.1s ease;

      &:hover {
        transform: scale(1.05);
      }

      .dark-mode-icon {
        color: $color-primary;
      }

      .light-mode-icon {
        color: #fff;
      }
    }
  }
}

.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  gap: 0.3rem;
  .icon {
    font-size: 20px;
  }

  .user-email {
    font-size: 12px;
    font-weight: 400 !important;

    .vip {
      color: rgb(255, 91, 206);
      transform: scale(0.75);
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #e5e4e4;
  }
}
