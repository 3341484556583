@import '../../styles/variables.scss';

.sfmc-account-admin {
  .account-count {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px 8px 16px;

    span {
      color: $color-primary;
      font-size: 12px;
      font-weight: 700;
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  dl {
    dt {
      font-weight: bold;
    }
    dd {
      margin-top: 5px;
    }
  }

  tr.MuiTableRow-head th:nth-child(1) {
    white-space: nowrap;
  }

  td,
  th {
    padding: 4px 16px;
  }

  tr.MuiTableRow-head {
    th {
      color: #fff;
      background-color: $color-primary;
      font-weight: bold;
    }
    &.filter-row {
      th {
        padding: 0;
        border: none;
        background-color: #fff;
        color: #000;
        * {
          color: #000 !important;
        }
      }
    }
  }

  tr.MuiTableRow-root td:nth-child(1) {
    // white-space: nowrap;
    font-weight: bold;
  }

  div.edit-bus-error {
    padding: 5px;
    max-height: 36px;
    .MuiAlert-message {
      overflow: hidden;
    }
  }

  button,
  a {
    &.MuiButton-containedPrimary {
      background: $color-primary;

      &:hover {
        background: $color-primary-light;
      }
    }
    &.MuiIconButton-colorPrimary {
      color: $color-primary;

      &:hover {
        color: $color-primary-light;
      }
    }

    &.MuiIconButton-colorError {
      color: #d81b1b;

      &:hover {
        color: #d81b1bea;
      }
    }
  }
}

.new-sfmc-account,
.edit-sfmc-account {
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
  padding: 2.4rem;
  position: relative;
}

.new-sfmc-account {
  width: max-content;
}

.icon {
  color: $color-primary;
}
