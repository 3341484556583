@import '../../styles/variables.scss';

.data-table {
  text-align: center;
  padding: 0 1rem;

  .MuiTableCell-root {
    padding: 0.2rem 1rem;
  }

  thead th {
    border: none;
  }

  th {
    font-weight: bold;
  }

  &__title {
    display: flex;
    gap: 0.2rem;

    .icon {
      font-size: 2rem;
    }
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .detail-panel {
    &__username {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      font-size: smaller;
      font-weight: 600;
      position: absolute;
      top: 25px;
      left: 15px;
      .icon {
        font-size: 20px;
      }
    }

    .scope__title {
      display: flex;
      align-items: center;
      gap: 0.1rem;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.2;

      &-mid {
        font-size: 10px;
        color: #5a5a5ade;
        font-weight: normal;
      }
    }

    .MuiSelect-multiple {
      padding: 0;
    }

    .chip {
      font-weight: bold;
      border-radius: 16px;

      &_active {
        background: $color-primary;
        color: #fff;
      }

      &_inactive {
        background: #f4f4f4;
        color: #5a5a5ade;
      }
    }
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  // .MuiTableSortLabel-icon,
  // .MuiTableSortLabel-root {
  //   color: #000000c2 !important;
  // }

  // .MuiPaper-root div > *,
  .MuiPaper-root,
  div[class^='Component-horizontalScrollContainer'] > div > div {
    border-radius: 12px !important;
  }

  div[class^='Component-horizontalScrollContainer'],
  .MuiToolbar-root {
    padding: 1rem 0;
  }

  .MTableHeader-header-13 {
    background-color: unset !important;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  .sb-avatar__text span {
    font-size: 14px;
  }

  .icon {
    color: $color-primary;

    &-copy svg {
      transform: scale(0.8);
    }

    &-role {
      display: inline;

      div {
        display: inline;
      }
    }
  }

  .small-icon {
    font-size: 1.5rem;
  }

  .MuiButton-startIcon {
    margin-left: unset;
    margin-right: 3px;
  }

  .MuiTableCell-footer {
    border: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTablePagination-menuItem {
  width: 100%;
}

.MuiSelect-select {
  min-height: unset !important;
}
