@import './variables';

.app.dark {
  background: #141d26;
  color: #fff;
  transition: all 0.2s ease;

  button {
    &.MuiButton-containedPrimary:not(:disabled) {
      background: $darkmode-color-primary;

      &:hover {
        background: $darkmode-color-primary-dark;
      }
    }
    &.MuiIconButton-colorPrimary {
      color: $darkmode-color-primary;

      &:hover {
        color: $darkmode-color-primary-dark;
      }
    }
  }

  .MuiAlert-root {
    background: rgb(253, 237, 237);
  }

  .introjs-tooltip {
    background: red;
  }

  .sidebar,
  .navbar,
  .wsfmc-notifications__popover {
    background: $darkmode-background-primary;
    transition: all 0.2s ease;
  }

  .sidebar {
    border-right: 0.5px solid rgb(48 48 48);
    transition: all 0.2s ease;

    hr {
      border: 0.5px solid rgb(48 48 48);
      border-bottom: none;
      transition: all 0.2s ease;
    }
  }

  .navbar {
    border-bottom: 0.5px solid rgb(48 48 48);
    transition: all 0.2s ease;
  }

  .logo,
  .dark-mode-icon,
  .refresh-list {
    color: #fff;
    transition: all 0.2s ease;
  }

  text,
  .search-field > * {
    color: #fff;
    fill: #fff;
    transition: all 0.2s ease;
  }

  .search-field:active,
  .search-field :focus {
    color: $darkmode-color-primary;
  }

  span,
  input::placeholder,
  .audits .icon {
    color: #fff !important;
    transition: all 0.2s ease;
  }

  //The previous definition (span, input::placeholder...) is setting the circular progress to white on dark mode
  //Which is making it invisible in some places (like datatable)
  //This is a workaround to set the color of the circular progress to blue on dark mode
  .audits .MuiLinearProgress-colorPrimary,
  .audits .MuiCircularProgress-colorPrimary .MuiLinearProgress-colorPrimary,
  .MuiCircularProgress-colorPrimary {
    // color: yellow !important;
    color: #1976d2 !important;
  }

  .data-table-container nav span {
    color: rgba(0, 0, 0, 0.54);
  }

  .browse .data-extension > *,
  .browse .data-extension span,
  .browse .data-extension input::placeholder,
  .browse .ruleGroup select,
  .browse .ruleGroup input,
  .audits .de-dependency span,
  .new-sfmc-account input,
  .sfmc-account-admin h2 svg,
  .sfmc-account-admin input {
    color: #fff;
  }

  .sfmc-account-admin .MuiTableCell-root,
  .sfmc-account-admin .MuiTableCell-root p {
    background: $darkmode-background-primary;
    transition: all 0.2s ease;
    color: #fff;
  }

  .filter-icon {
    color: #ffffff9c;
  }

  .audits text {
    // fill: #222;
  }

  .card,
  .audits,
  .new-sfmc-account,
  .sfmc-account-admin {
    background: $darkmode-background-primary;
    box-shadow: rgb(62 62 62 / 20%) 0px 2px 8px 0px;
    transition: all 0.2s ease;
  }

  .audits {
    a:hover {
      color: $darkmode-color-primary-dark !important;
    }
    .bu-list-item {
      .bu-name,
      .bu-mid,
      .bu-mid-text,
      .bu-repository svg,
      .bu-flags,
      .bu-accordion-details strong,
      .audit-item-icon {
        color: #fff;
      }

      button.copy-bu-field svg {
        color: #b6b6b6;
      }

      .expand-icon {
        color: #b6b6b6;
        transition: all 0.1s ease;
        &:hover {
          color: $darkmode-color-primary-dark;
        }
      }
    }

    .rdt_TableRow {
      &.bs-results-error {
        .rdt_TableCell div {
          color: red !important;
        }
      }
      &.bs-results-warn {
        .rdt_TableCell div {
          color: rgb(255, 178, 89) !important;
        }
      }
    }

    .bs-results-collecting * {
      color: $darkmode-color-primary !important;
    }

    .expanded-row {
      td {
        table.package-install-result {
          tbody {
            tr.bs-results-error {
              td {
                color: red !important;
              }
            }
          }
        }
      }
    }
  }

  .audits .de-dependency label,
  .audits .de-dependency input,
  .browse .data-extension label,
  .browse .data-extension input,
  .browse .dataextension-details svg,
  .paging-component button {
    color: #fff;
  }

  .business-unit-details .audits,
  .data-table-container,
  .audits.de-dependency,
  .audits .de-dependency__container,
  .browse .data-extension,
  .browse .ruleGroup select,
  .browse .ruleGroup input {
    background: $darkmode-background-secondary;
  }

  .browse a:hover {
    color: $darkmode-color-primary-dark !important;
  }

  .browse .ruleGroup {
    background-color: rgb(17 18 36 / 20%);
  }

  .audits .rdt_TableRow:nth-child(odd),
  .audits .rdt_ExpanderRow {
    color: #fff;
    background-color: #192531;
  }

  .audits .rdt_TableRow:nth-child(even) {
    background-color: $darkmode-background-primary;
  }

  .audits .rdt_Table .rdt_TableCell div:first-child {
    color: #fff;
  }

  .audits .rdt_TableRow:hover {
    background-color: rgb(25 37 49);
    color: $darkmode-color-primary-dark;

    .rdt_TableCell > * {
      color: $darkmode-color-primary-dark !important;
    }
  }

  .audits .rdt_Table .rdt_TableCell div:first-child:hover {
    border: thin solid lightgray;
    background-color: #192531;
  }

  .audits .rdt_Pagination > * {
    color: #fff;
  }

  .data-table-container .rdt_TableRow button {
    color: #fff;
  }

  .data-table-container > *,
  .data-table-container nav {
    background: rgb(25 37 49);
    color: #fff;
  }

  .audits .de-dependency .graphviz-parent svg g.graph polygon {
    fill: transparent;
  }

  .audits .de-dependency .graphviz-parent svg g.graph .node polygon,
  .audits .de-dependency .graphviz-parent svg g.node path,
  .audits .de-dependency .graphviz-parent svg g.node path.selected,
  .audits .de-dependency .graphviz-parent svg g.node polygon.selected {
    stroke: rgb(241, 237, 237);
  }

  .audits .de-dependency .graphviz-parent svg g.node path.selected + text,
  .audits .de-dependency .graphviz-parent svg g.node polygon.selected + text {
    // fill: #222;
  }

  .audits .de-dependency .graphviz-parent svg g.node path.selected,
  .audits .de-dependency .graphviz-parent svg g.node polygon.selected {
    fill: #ffb55e;
  }

  .audits .de-dependency .graphviz-parent svg g.node ellipse {
    fill: rgb(255, 117, 138);
  }

  .browse .custom-value-editor.invalid input,
  .browse .custom-value-editor.invalid select,
  .browse .custom-value-editor.invalid option {
    background-color: rgb(253 169 169);
  }

  .browse .Mui-disabled {
    color: #ffffff42;
  }

  .audits .datable-container.paging .rdt_Table .rdt_TableCell div:first-child {
    color: rgb(80, 80, 80);
  }
  .audits .datable-container.paging .rdt_Table button {
    color: rgb(200, 200, 200);
  }
  .audits .datable-container.paging .paging-component,
  .audits .datable-container.paging .paging-component button {
    color: rgb(80, 80, 80);
  }

  .datable-container {
    &.paging {
      .rdt_Table .rdt_TableCol button,
      .rdt_Table .rdt_TableCol,
      .rdt_Table .rdt_TableCell {
        animation-duration: 0.5s;
        animation-name: pagingProgressDark;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }

  @keyframes pagingProgressDark {
    from {
      border-color: #222;
    }

    to {
      border-color: #555;
    }
  }

  .bu-list-item__panel {
    background: $darkmode-background-secondary;
    border: solid thin #141d26 !important;

    &:hover {
      background: $darkmode-background-secondary;
    }
  }

  .sidebar-center__icon,
  .audits .audits-title__icon,
  .audits a,
  .audits .bu-list-item .bu-lastSyncDate button,
  .audits .bu-list-item .MuiAccordion-root .bu-accordion-details a,
  .audits .clear-button,
  .audits .search-close-icon {
    color: $darkmode-color-primary;
  }

  .MuiAccordion-root {
    &.Mui-disabled:hover {
      background: rgba(17, 25, 32, 0.5803921569);
    }
  }

  .backButtonBox a,
  .audits .bu-list-item .bu-avatar,
  .browse .ruleGroup button,
  thead,
  .sfmc-account-admin a.MuiButton-containedPrimary {
    background: $darkmode-color-primary;
  }

  .bu-details-seperator {
    border-color: #141d2642;
  }

  .apexcharts-tooltip > *,
  .apexcharts-tooltip-text > * > * {
    color: #222 !important;
  }

  .css-2pbtu6-MuiTypography-root,
  .css-kr7kgz {
    color: rgba(255, 255, 255, 0.9);
  }

  .data-table {
    .MuiPaper-root,
    .MuiMenu-list {
      background-color: $darkmode-background-primary;
    }
    .MuiInputBase-input,
    .MuiInputBase-root svg,
    td,
    label,
    .MuiSvgIcon-root,
    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows,
    .MuiTablePagination-actions .Mui-disabled,
    .no-account-title {
      color: #fff !important;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid rgba(255, 255, 255, 0.42);
    }
    .MuiTableCell-root {
      border-bottom: 1px solid rgb(19 25 30 / 24%);
    }
    .MuiTablePagination-actions .Mui-disabled svg {
      color: rgba(255, 255, 255, 0.418) !important;
    }

    .sb-avatar__text {
      background: #fff !important;
    }

    .sb-avatar__text span {
      color: #222 !important;
    }
  }

  .sfmc-account-admin .MuiTableCell-root {
    border-bottom: 1px solid rgb(19 25 30 / 24%);
  }

  .new-sfmc-account .MuiFormLabel-root {
    color: rgba(255, 255, 255, 0.514);
  }

  .dashboard {
    .MuiTableCell-root {
      border-bottom: 1px solid rgb(19 25 30 / 24%);
    }
  }

  .ampscript-editor__editor {
    textarea {
      background-color: rgb(32, 32, 32);
      border: none;
      color: white;
    }
  }
  .ampscript-editor__preview {
    background-color: rgb(32, 32, 32);
    color: white;
    border: none;

    &.loading {
      background-color: rgb(107, 107, 107);
      color: rgb(106, 106, 106);
      cursor: progress;
    }
  }

  .wsfmc-notifications {
    & .MuiBadge-badge {
      background-color: $darkmode-color-primary;
    }
  }

  .wsfmc-notifications__popover {
    border: 1px solid rgb(48 48 48);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    &-header {
      border-bottom: solid 1px rgb(48 48 48);
    }
  }

  .wsfmc-notification {
    &.unread {
      background-color: #334760;
    }

    &:hover {
      background: #405773;
    }

    &__date {
      color: #bdbdbd;
    }

    &__header {
      &-title {
        color: #fff;
      }
    }

    &__unread-mark {
      background-color: $darkmode-color-primary;
    }
  }
}

.app {
  transition: all 0.2s ease;
}
