@import '../styles/variables.scss';

.autocomplete-option-item {
  position: relative;
  width: 100%;
  min-height: 25px;
  margin: 3px;

  &__history-icon {
    position: absolute;
    top: 50%;
    right: 0;
    color: grey;

    svg {
      font-size: 1rem;
    }
  }
  .search-match {
    color: rgb(89, 124, 219) !important;
    font-weight: bold !important;
  }
}

.browse {
  // margin: 20px 0 0 20px;

  .darkHover {
    &:hover {
      color: rgb(12, 12, 114);
    }
  }

  .data-extension {
    width: 100%;
    background: #f1f1f1;
    border-radius: 0.8rem;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    padding: 2rem;

    & > div.MuiGrid-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
    }
  }

  .DataExtensionHelpers {
    .MuiAccordionSummary-root {
      background-color: #f5f5fa;
      /* background-color: rgb(238, 251, 255); */
    }
  }

  &.dataExtensionPopover {
    overflow: auto;

    .MuiTabs-root + button {
      top: 4px;
    }

    button.MuiTab-root.Mui-selected {
      /* background-color: rgb(238, 251, 255); */
      background-color: #f5f5fa;
      color: $color-primary;
      font-weight: bold;
    }

    table {
      border-collapse: collapse;
      font-size: 0.9em;
      min-width: 400px;
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    table thead tr {
      background-color: $color-primary;
      color: #ffffff;
      text-align: left;
    }

    table th {
      padding: 12px 15px;
      white-space: nowrap;
    }

    table td {
      padding: 12px 15px;
    }

    // table thead th {
    //   white-space: nowrap;
    //   text-align: center;
    //   padding: 0 0 0 10px;
    // }

    table tbody tr {
      border-bottom: 1px solid #dddddd;
    }

    table tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    table tbody tr:last-of-type {
      border-bottom: 2px solid $color-primary;
    }

    strong.fieldTitle {
      display: inline-block;
      padding: 10px;
      font-size: 20px;
    }

    // table,
    // table tbody,
    // table tbody td,
    // table tbody tr {
    //   border: none;
    //   border-spacing: 0;
    // }

    tbody {
      tr.row-select-all {
        padding-top: 0;
        padding-bottom: 0;
        td {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      tr:nth-child(odd) {
        background-color: #efefef;
      }

      tr {
        border: none !important;
        font-weight: bold;
      }

      tr.pk {
        color: $color-primary;
        font-weight: bold;
      }

      tr.required {
        color: rgb(194, 7, 7);
        font-weight: bold;
      }

      // td.fieldName {
      //   border-right: solid thin #ddd;
      //   text-align: right;
      //   font-weight: bold;
      //   padding: 0 5px 0 0;
      // }

      // td.fieldPK,
      // td.fieldRequired,
      // td.fieldDefault,
      td.fieldType {
        white-space: nowrap;
      }
    }
  }

  &.DataExtensionHelpers {
    .MuiCollapse-wrapper .DataExtensionHelpers {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .MuiAccordionSummary-content .MuiTypography-root {
      color: $color-primary;
      font-weight: bold;
    }

    pre.de-select-statement {
      text-align: left;
      white-space: pre-wrap;
      /* border-top: solid thin lightgrey; */
      padding: 0 10px 10px 10px;
      margin: 5px 10px 10px 10px;
      max-width: 100%;
      font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    .de-select-statement-buttons {
      min-width: 70px;

      svg {
        font-size: 20px;

        &:hover {
          color: $color-primary !important;
        }
      }

      a,
      button {
        color: $color-primary;
      }
    }
  }

  .query-grid-item fieldset {
    border: thin solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .ruleGroup {
    border: thin solid rgba(0, 0, 0, 0.13);
    background-color: rgba(204, 209, 255, 0.2);
    padding: 1rem;

    button {
      background: $color-primary;
      border: none;
      outline: none;
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      padding: 4px 10px;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
          0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        background: $color-primary-light;
      }
    }

    select,
    input {
      outline: none;
      border: none;
      padding: 9px 12px;
      color: #222;
      border-radius: 4px;
    }
  }

  .de-selection {
    min-width: 600px;
  }

  .datable-container {
    margin-bottom: 30px;
    border-radius: 12px;

    &:not(.loading) > div {
      max-width: 100%;
      margin: 2px 0 1.2rem 0;
    }
  }

  div {
    &.dataextension-details {
      padding: 0 0 0 8px;

      ul {
        padding: 0 0 0 0;
      }

      ul li div,
      ul li {
        padding: 0;
        margin: 0;
      }
    }
  }

  .MuiGrid-item.orderBy-warning-grid {
    padding-top: 7px;

    .orderBy-warning {
      max-height: 35px;

      .MuiAlert-icon {
        padding: 2px 0 2px 0;
      }

      .MuiAlert-message {
        padding: 2px 0 2px 0;
        overflow: hidden;
      }
    }
  }

  .query-grid-results {
    width: 100%;
  }

  .datable-container {
    // .progress-comp {
    //   color: #222;
    //   width: 100%;
    // }

    &.paging {
      .paging-component {
        border-color: rgb(233, 233, 233);
        color: rgb(233, 233, 233);
      }

      .rdt_Table .rdt_TableCol button,
      .rdt_Table .rdt_TableCol,
      .rdt_Table .rdt_TableCell {
        animation-duration: 0.5s;
        animation-name: pagingProgress;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }

    @keyframes pagingProgress {
      from {
        border-color: #ddd;
        color: rgb(233, 233, 233);
      }

      to {
        border-color: #fff;
        color: rgb(233, 233, 233);
      }
    }

    .paging-component.count-only {
      padding: 2px 0 5px 0;
    }

    .rdt_Table {
      border-collapse: collapse;
      font-size: 0.9em;
      min-width: 400px;
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    .rdt_TableHeadRow > * {
      color: #f3f3f3;
      text-align: left;

      div {
        &:hover {
          color: #ffffff;
          opacity: 1;
        }
      }
    }

    .rdt_TableHeadRow > * {
      padding: 8px 0px !important;
      font-size: 14px;
      font-weight: bold;
      // min-width: 160px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    }

    .rdt_TableHeadRow > *:not(:last-child) {
      border-right: 2px solid rgba(0, 0, 0, 0.05);
    }

    .rdt_TableRow {
      border: none !important;
    }

    .rdt_TableRow > * {
      // padding: 9px 15px;
      // font-size: 14px;
      // border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      padding: 9px 5px 9px 0;
      font-size: 14px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: flex-end;
      position: relative;
    }

    .rdt_TableRow > *:not(:last-child) {
      border-right: 2px solid rgba(0, 0, 0, 0.05);
    }

    .rdt_TableRow button {
      border-radius: 50%;
      width: 28px;
      height: auto;
    }
  }

  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: whitesmoke;
    }

    &:hover {
      background-color: rgb(243, 243, 252);
      color: rgb(10, 9, 58);
      font-weight: bold;
    }
  }

  .rdt_Table {
  }
  .rdt_TableCol {
    // border: thin solid #ddd;
    padding: 0;

    button {
      min-width: 0 !important;
      text-transform: none;
      color: #222;
      font-weight: bold;
      font-size: 10pt;
      line-height: 1.3 !important;
      transform: scale(0.9);
      font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

      span.MuiButton-endIcon {
        margin: 0;
      }
    }

    &[data-column-id$='_$pk'],
    &.pk {
      background-color: #0060af1a;
    }

    &[data-column-id$='_$req'],
    &.req {
      background-color: rgba(197, 26, 26, 0.1);
    }
  }
  .rdt_TableCol_Sortable {
    display: flex;
    justify-content: center;
  }

  div.rdt_TableCell .i-cell {
    // border: thin solid #ddd;
    padding: 0 5px 0 5px;
    font-size: 10pt !important;
    font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;

    div:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // &.pk {
    //   background-color: #0060af1a;
    // }

    // &.req {
    //   background-color: rgba(197, 26, 26, 0.1);
    // }

    &:hover .i-cell.selectable {
      -webkit-user-select: all;
      user-select: all;
    }
  }

  .de-selection {
    fieldset {
      border-color: gray !important;
    }

    label {
      color: #222;
    }
    &.selected {
      fieldset {
        border-color: rgb(27 145 27) !important;
      }

      input {
        color: rgb(27 145 27) !important;
        font-weight: bold;
      }
    }

    &.valid {
      input {
        color: rgb(89, 124, 219);
        font-weight: bold;
      }
    }

    &.busy input {
      background-color: rgb(228, 228, 228);
      color: rgb(155, 155, 155);
    }

    &.invalid input {
      background-color: rgb(255, 230, 230);
      color: rgb(155, 155, 155);
    }
  }

  .sqlQuery.invalid {
    color: rgb(161, 18, 18);
  }

  .custom-value-editor {
    display: inline-block;
  }

  .rule-value {
    width: 400px;
  }

  .custom-value-editor.invalid input,
  .custom-value-editor.invalid select,
  .custom-value-editor.invalid option {
    background-color: rgb(255, 230, 230);
  }

  &.download-panel {
    margin: 0;
    padding: 22px 12px 5px 12px;
    border: thin solid #222;

    .lookup-download-warning p:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.DataExtensionDetails {
  .MuiDialog-paper {
    width: 100%;
    height: 100%;
    background: rgb(243 242 239);
    margin-top: 90px;
    margin-bottom: 45px;
    min-width: 650px;
    max-width: min-content;
  }
}

.xml-template-popper.flash-popper .popper-box.copied {
  padding: 5px;
  margin-left: 5px;
  border-radius: 10px;
  background-color: rgb(236, 255, 247);
  /* border: solid thin #ccc; */
}

.select-sql-statement-popper.flash-popper .popper-box.copied {
  padding: 5px;
  margin-left: 5px;
  border-radius: 10px;
  background-color: rgb(236, 255, 247);
  /* border: solid thin #ccc; */
}

.flash-popper {
  position: absolute;
  z-index: 10000;
}

button {
  &.MuiButton-containedPrimary {
    background: $color-primary;

    &:hover {
      background: $color-primary-light;
    }
  }
  &.MuiIconButton-colorPrimary {
    color: $color-primary;

    &:hover {
      color: $color-primary-light;
    }
  }

  &.MuiIconButton-colorError {
    color: #d81b1b;

    &:hover {
      color: #d81b1bea;
    }
  }
}

.rowdata-cell-tooltip {
  font-size: 10pt;
}
