@import '../../../../styles/variables';

.wsfmc-notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  position: relative;
  cursor: pointer;
  width: 100%;

  &.unread {
    background-color: #f5f5f5;
  }

  &.dismissed {
    display: none;
  }

  &:hover {
    background-color: #e0e0e0;
  }

  &__body {
    overflow: hidden;
    // max-height: 30px;
    opacity: 1;
    transition: max-height 0.5s, opacity 0.5s;
    font-size: 14px;

    &.expanded {
      max-height: 500px;
      opacity: 1;
    }

    &:not(.expanded) {
      opacity: 0.8;
    }

    img {
      width: 100%;
    }

    a {
      color: $color-primary;
      text-decoration: none;
    }
  }

  &__date {
    margin-top: 6px;
    font-size: 12px;
    color: #595959;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
    }
  }

  &__unread-mark {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff0000;
    margin-left: 8px;
  }
}
