@import '../variables.scss';

.home {
  display: flex;

  &-container {
    position: relative;
    flex: 6;
    width: 10%;
  }

  &-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.main {
  min-height: 90vh;
  padding: 2.4rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  // margin-left: 200px; //sidebar
  margin-top: 50px;
  transition: all 0.2s ease;

  .row {
    display: flex;
    gap: 2rem;
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .apexcharts-title-text {
    font-weight: 700;
    font-size: 16px;
  }
}

.main-color {
  color: $color-primary;
}

.main-color-light {
  color: $color-primary-light;
}

.main-color-dark {
  color: $color-primary-dark;
}

.main-backgrouund {
  background: $color-primary;
}

.main-backgrouund-light {
  background: $color-primary-light;
}

.main-backgrouund-dark {
  background: $color-primary-dark;
}
